@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background font-sans;
  }
  .h1 {
    @apply font-heading xl:text-5xl leading-tight lg:text-5xl md:text-3xl sm:text-2xl xs:text-2xl font-medium;
  }
  .h2 {
    @apply font-heading xl:text-3xl leading-tight lg:text-3xl md:text-2xl sm:text-xl xs:text-xl font-medium;
  }
  p {
    @apply font-sans xl:text-xl lg:text-xl md:text-lg sm:text-lg xs:text-lg font-medium;
  }
  .btn {
    @apply bg-gradient-to-r from-[#ffb100] to-yellow-400 text-black py-2 px-4 rounded hover:from-[#ffb100] hover:to-yellow-500 h-10 hover:opacity-90 lg:w-96 xl:w-96 md:w-48  focus:shadow-lg xl:text-xl lg:text-xl md:text-lg sm:text-sm xs:text-sm shadow-lg rounded-md;
  }
  .input {
    @apply xl:h-[48px] lg:h-[48px] md:h-[48px] sm:h-[32px] xs:h-[32px] rounded-md capitalize;
  }
  .input,
  .textarea {
    @apply focus:outline-none border md:w-96 sm:w-80 xs:w-80 p-2 focus:border-accent focus:ring-accent block focus:ring-1 focus:ring-opacity-50 rounded-md focus:shadow-lg xl:text-xl lg:text-xl md:text-lg sm:text-sm xs:text-xs border-gray-300 shadow-md;
  }
  .textarea {
    @apply h-[180px] capitalize rounded-md resize-none;
  }
}
